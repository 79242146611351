<template>
  <div>
    <b-row align-h="center">
      <b-col md="5">
        <b-card class="pt-1 pb-1">
          <div class="d-flex align-items-center justify-content-between mb-2">
            <h2 class="mb-0">Account Key</h2>
            <feather-icon
              class="cursor-pointer"
              icon="CopyIcon"
              size="20"
              v-b-tooltip.hover.right
              title="Copy"
              @click="
                copyKey(
                  `${$store.state.userData.db}&cID=${$store.state.userData.cId}`
                )
              "
            />
          </div>
          <div
            style="
              border: 2px solid lightgray;
              border-radius: 10px;
              word-break: break-all;
            "
            class="p-1 d-flex align-items-center"
          >
            <h4 class="m-0">
              {{ this.$store.state.userData.db }}&cID={{
                this.$store.state.userData.cId
              }}
            </h4>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  VBTooltip,
  BFormGroup,
  BForm,
  BSpinner,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useClipboard } from "@vueuse/core";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,

    VBTooltip,
    BFormGroup,
    BForm,

    BSpinner,
    BFormTextarea,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {};
  },
  setup() {
    const toast = useToast();

    const { copy } = useClipboard();

    const copyKey = (key) => {
      //   console.log(key);
      copy(key);

      toast({
        component: ToastificationContent,
        props: {
          title: "Key copied",
          icon: "CopyIcon",
          variant: "success",
        },
      });
    };

    return {
      copyKey,
    };
  },
  methods: {},
};
</script>

<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.card-body {
  padding: 0.85rem;
}
</style>
